<template>
  <!-- 收银订单页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 订单号 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="订单号">
                    <a-input v-model="queryParam.orderNo" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>
                <!-- 用户昵称 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  <a-button type="primary" @click="exportDataList">导出</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 订单类型插槽 -->
            <span slot="orderType" slot-scope="text">
              {{ text == 1 ? '普通订单' : text == 2 ? '拍图订单' : '' }}
            </span>
            <!-- 优惠金额插槽 -->
            <span slot="couponDiscountPrice" slot-scope="text"> {{ text ? text : '0' }} </span
            ><!-- 优惠金额插槽 -->
            <span slot="storeCouponDiscountPrice" slot-scope="text">
              {{ text ? text : '0' }}
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { cashOrderList } from '@/api/modular/mallLiving/orderAdmin'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '80px',
    fixed: 'left',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '订单号',
    align: 'center',
    dataIndex: 'orderNo',
    key: 'orderNo',
    width: '130px',
  },
  {
    title: '订单类型',
    dataIndex: 'orderType',
    key: 'orderType',
    align: 'center',
    width: '130px',
    scopedSlots: {
      customRender: 'orderType',
    },
  },
  {
    title: '用户昵称',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '130px',
    align: 'center',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    width: '100px',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
    width: '130px',
  },
  {
    title: '店铺ID',
    dataIndex: 'storeIdNo',
    align: 'center',
    width: '130px',
  },
  {
    title: '店铺昵称',
    dataIndex: 'storeNickname',
    align: 'center',
    width: '130px',
  },
  {
    title: '平台优惠金额',
    dataIndex: 'couponDiscountPrice',
    key: 'couponDiscountPrice',
    align: 'center',
    width: '100px',
    scopedSlots: {
      customRender: 'couponDiscountPrice',
    },
  },
  {
    title: '店铺优惠金额',
    dataIndex: 'storeCouponDiscountPrice',
    key: 'storeCouponDiscountPrice',
    align: 'center',
    width: '100px',
    scopedSlots: {
      customRender: 'storeCouponDiscountPrice',
    },
  },
  {
    title: '订单金额',
    dataIndex: 'orderPrice',
    key: 'orderPrice',
    align: 'center',
    width: '100px',
  },
  {
    title: '支付金额',
    dataIndex: 'payPrice',
    key: 'payPrice',
    align: 'center',
    width: '100px',
  },
  {
    title: '使用积分',
    dataIndex: 'integral',
    key: 'integral',
    align: 'center',
    width: '100px',
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    key: 'payTime',
    align: 'center',
    width: '140px',
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {},
    }
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date).getTime()
      // var year = date.getFullYear()
      // var month = date.getMonth() + 1
      // var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return date
    },
    exportDataList() {
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 2
      this.axios
        .post(`/order/cashOrderPage`, this.queryParam, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '收银订单记录_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 1
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      cashOrderList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
